<template>
  <div>
    <van-sticky>
      <title-bar2 title="考研资讯" />
    </van-sticky>
    <div class="article-title">
      <span class="article-title_name">{{ articleName }}</span>
      <span class="article-title_date">发布时间：{{ formatDate(articleDate) }} 浏览量：{{ articleCount }}</span>
    </div>
    <van-image v-if="articleImageCode" class="article-img" :src="articleImg" @click="onPreviewClick">
      <template v-slot:loading>
        <van-loading type="spinner" size="20" />
      </template>
    </van-image>
    <div class="article-content" v-html="articleContentHtml"></div>

    <van-image-preview closeable :show-index="false" v-model="preview" :images="previewImages"></van-image-preview>
  </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Tools from "@/tool";
import Config from "@/api/service";
import Manager from "@/manager";
export default {
  name: "MTModule",
  components: {
    TitleBar2,
  },
  props: {
  },
  data() {
    return {
      preview: false,
      articleGuid: null,
      articleName: null,
      articleContent: null,
      articleDate: null,
      articleCount: null,
      articleImageCode: null,
    };
  },
  computed: {
    articleImg() {
      if (this.articleImageCode) {
        return `${Config.article.image}?ArticleImageCode=${this.articleImageCode}`;
      }
    },
    previewImages() {
      return [this.articleImg];
    },
    articleContentHtml() {
      return Tools.textToHtml(this.articleContent);
    },
    formatDate() {
      return function (str) {
        if (str) {
          return Tools.formatDate(Tools.convertDate(str), 'yyyy-MM-dd')
        }
      }
    }
  },
  methods: {
    //获取路由参数
    getRoutParams() {
      this.articleGuid = this.$route.params.articleGuid;
    },
    //查询文章信息
    getArticleInfo() {
      var model = {
        ArticleGuid: this.articleGuid
      };
      this.$axios
        .post(Config.article.info, model)
        .then((res) => {
          this.articleName = res.data.Data.ArticleName;
          this.articleContent = res.data.Data.ArticleContent;
          this.articleDate = res.data.Data.ArticleCreateTime;
          this.articleCount = res.data.Data.ArticlePreviewCount;
          this.articleImageCode = res.data.Data.ArticleImageCode;
        })
        .catch((error) => {
          this.$message.error(error.message);
        });
    },
    //添加访问商品记录
    addVisitProductRecord() {
      Manager.addVisitProductRecord("考研资讯");
    },
    //点击预览
    onPreviewClick() {
      this.preview = true;
    },
  },
  mounted() {
    this.getRoutParams();
    this.getArticleInfo();
    this.addVisitProductRecord();
  }
};
</script>

<style lang="less" scoped>
.article-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .article-title_name {
    margin: 10px;
    font-size: 20px;
    font-weight: 500;
  }

  .article-title_date {
    margin: 10px 0;
    word-spacing: 20px;
  }
}

.article-content {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 14px;
}

.article-img {
  width: 100%;
  display: block;
}
</style>